<style>
  footer {
    margin-top: auto;
  }
</style>

<footer class="fx-item fx-grow-zero">
  <!-- <p>
    Site build with
    <a href="https://svelte.dev">Svelte</a>
  </p> -->
  <p>
    There are no copyrights. Source code at
    <a
      target="_blank"
      href="https://github.com/vatz88/vatz88.github.io"
      rel="noopener">
      github.com/vatz88/vatz88.github.io
    </a>
  </p>
</footer>
