<div class="fx-item fx-grow-zero">
  <p>I'm a JavaScript and Web enthusiast.</p>
  <p>
    I put down my thoughts on
    <a target="_blank" href="https://blog.vatz88.in" rel="noopener">
      blog.vatz88.in
    </a>
    and
    <a target="_blank" href="https://twitter.com/vatz88" rel="noopener">
      twitter.com/vatz88
    </a>
  </p>
  <p>
    Most of my code and contributions are on
    <a target="_blank" href="https://github.com/vatz88" rel="noopener">
      GitHub.com/vatz88
    </a>
    and
    <a
      target="_blank"
      href="https://stackoverflow.com/users/5733059/vatz88"
      rel="noopener">
      StackOverflow:vatz88
    </a>
  </p>
  <p>
    I love emails. You can write one to
    <a href="mailto:hello@vatz88.in" rel="noopener">hello@vatz88.in</a>
  </p>
  <p>
    Also, I love playing chess. Always up for a game
    <a
      href="https://www.chess.com/member/vatz88"
      rel="noopener">chess.com:vatz88</a>
  </p>
</div>
